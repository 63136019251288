import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@state/actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import { getTextClassFromLength } from '@helpers';
import { useInCollections } from '@helpers';

import './InsightStarterPoint.scss';

const InsightStarterPoint = ({ id, text }) => {
  const dispatch = useDispatch();
  const isInCollections = useInCollections(`insightStarterPoints`, id);
  const focusOnModalCloseRef = useRef(null);

  const openAddToCollection = () => {
    dispatch(
      actions.openAddToCollection(
        `insightStarterPoints`,
        { id, text },
        focusOnModalCloseRef
      )
    );
  };

  return (
    <div
      className="mars-insight-starter-point"
      ref={focusOnModalCloseRef}
      tabIndex="0">
      <div
        className={`mars-insight-starter-point__text`}>
        {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <button
        className="mars-insight-starter-point__save-btn mars-card__save-btn mars-button--svg"
        onClick={openAddToCollection}
        aria-label="Save">
        <MarsIcon
          icon="save"
          className={classnames({ 'mars-icon--fill': isInCollections })}
        />
      </button>
    </div>
  );
};

export default InsightStarterPoint;

InsightStarterPoint.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
