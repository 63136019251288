import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import MomentCard from '@components/molecules/MomentCard/MomentCard';
import TabbedContent from '@components/organisms/TabbedContent/TabbedContent';
import { getDrupalURL } from '@helpers';

import './RelatedMoments.scss';

const MomentCardList = ({ momentItems }) => {
  const petLifestageTitle = momentItems.moments[0]?.petParentRole;
  const petLifestageImage = momentItems.moments[0]?.petParentRoleImage.path;
  console.log(petLifestageImage);
  return (
    <>
      {petLifestageTitle && (
        <div class="mars-grid mars-related-moments__header mars-margin-y--lg">
          <div class="mars-grid-col-7 mars-related-moments__header-text">
            <span class="mars-stage__meta-title mars-zeta mars-text-colour--text-meta-hero">
              Pet Parent Role
            </span>
            <h2 class="mars-gamma">{petLifestageTitle}</h2>
            <div>{momentItems.moments[0].petParentRoleDescription}</div>
          </div>

          {petLifestageImage && typeof petLifestageImage === 'string' ? (
            <div className="mars-grid-col-5 mars-related-moments__header-image">
              <img
                src={`${petLifestageImage}`}
                alt={momentItems.moments[0].petParentRoleImage.alt}
              />
            </div>
          ) : (
            <div className="mars-grid-col-5 mars-related-moments__header-image">
              <GatsbyImage
                image={getImage(petLifestageImage)}
                alt={momentItems.moments[0].petParentRoleImage.alt}
              />
            </div>
          )}
        </div>
      )}
      <ul className="mars-related-moments__list">
        {momentItems.moments.map((moment, index) => {
          return (
            <li
              key={moment.title + index}
              className="mars-related-moments__list-item">
              <MomentCard {...moment} />
            </li>
          );
        })}
      </ul>
    </>
  );
};

const RelatedMoments = ({
  relatedMoments,
  species,
  layout = `regular`,
  tabbed = false
}) => {
  const { title, moments } = relatedMoments;

  const lifestagesObj = {};

  // Loops over each moment, and updates the moments array by spreading
  // in the current moments array, and then adding the new moment.
  moments.forEach((moment) => {
    lifestagesObj[moment.lifestage] = lifestagesObj[moment.lifestage] || {};
    lifestagesObj[moment.lifestage].moments =
      lifestagesObj[moment.lifestage].moments || [];
    lifestagesObj[moment.lifestage].moments = [
      ...lifestagesObj[moment.lifestage].moments,
      moment
    ];
  });

  const momentTabs = Object.keys(lifestagesObj).map((lifestage) => {
    return (
      <div className="mars-grid" data-label={lifestage} key={lifestage}>
        <MomentCardList
          species={species}
          momentItems={lifestagesObj[lifestage]}
        />
      </div>
    );
  });

  return (
    <section className={`mars-related-moments mars-related-moments--${layout}`}>
      <div className="mars-container mars-margin-y--xl">
        <h2 className="mars-gamma mars-margin-bottom--lg mars-related-moments__title">
          {title}
        </h2>
        {/* The below checks whether the tabbed prop is true, and also whether there are 2 or more tabs */}
        {tabbed && tabbed === true && Object.keys(lifestagesObj).length >= 2 ? (
          <TabbedContent
            variant="primary"
            tabListClassName="mars-margin-bottom--sm">
            {momentTabs}
          </TabbedContent>
        ) : (
          <MomentCardList momentItems={{ moments }} />
        )}
      </div>
    </section>
  );
};

RelatedMoments.propTypes = {
  relatedMoments: PropTypes.object.isRequired,
  species: PropTypes.string.isRequired,
  layout: PropTypes.string
};

export default RelatedMoments;
