import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import Card from '@components/atoms/Card/Card';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import './PetPawtalCard.scss';
import { getDrupalURL } from '@helpers';

const PetPawtalCard = ({ data }) => {
  const { title, url, imageUrl, imageAlt, created } = data;

  const dateCreated = created.toLocaleDateString(`en-GB`, {
    day: `numeric`,
    month: `short`,
    year: `numeric`
  });

  const CardContent = () => (
    <>
      {imageUrl && (
        <div className="mars-pet-pawtal-card__image-wrapper">
          {/* <img src={`${getDrupalURL()}${imageUrl}`} alt={imageAlt} /> */}
          <GatsbyImage image={getImage(imageUrl)} alt={imageAlt} />
          <div className="mars-pet-pawtal-card__icon">
            <MarsIcon icon="playButton" />
          </div>
        </div>
      )}
      <div className="mars-pet-pawtal-card__content-wrapper">
        <h1 className="mars-delta mars-pet-pawtal-card__title">{title}</h1>
        <div className="mars-body-large mars-pet-pawtal-card__info mars-body-small">
          <time dateTime={created.toISOString()}>{dateCreated}</time>
          {` `}
        </div>
      </div>
    </>
  );

  return (
    <Card className="mars-pet-pawtal-card mars-margin-bottom--md">
      <article>
        {url ? (
          <Link className={`mars-pet-pawtal-card__link`} to={url}>
            <CardContent />
          </Link>
        ) : (
          <CardContent />
        )}
      </article>
    </Card>
  );
};

PetPawtalCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
    created: PropTypes.instanceOf(Date).isRequired
  }).isRequired
};

export default PetPawtalCard;
