export default ({ nodeMomentJson }) => {
  const {
    drupal_id,
    title,
    field_description,
    path,
    relationships,
    field_synapse_link,
    field_pet_pawtal_video_link
  } = nodeMomentJson;

  const mapContentSnippets = (snippets) =>
    snippets.map((snippet) => ({
      id: snippet.drupal_id,
      text: snippet.field_snippet ? snippet.field_snippet.value : snippet.title,
      datePublished: snippet.created
    }));

  return {
    id: drupal_id,
    path: path.alias,
    species:
      relationships.field_moment_stage.relationships.field_species.name.toLowerCase(),
    synapseBanner: {
      url: field_synapse_link ? field_synapse_link.url : null,
      title: field_synapse_link ? field_synapse_link.title : null
    },
    momentHeader: {
      stage: relationships.field_moment_stage.title,
      title,
      description: field_description && field_description.value,
      image: {
        path:
          relationships.field_moment_image &&
          relationships.field_moment_image.relationships.image.uri.url,
        alt: title
      }
    },
    thinkingFeelingDoing: [
      {
        icon: `thinking`,
        title: `Thinking...`,
        listItems: mapContentSnippets(relationships.field_moment_thinking)
      },
      {
        icon: `feeling`,
        title: `Feeling...`,
        listItems: mapContentSnippets(relationships.field_moment_feeling)
      },
      {
        icon: `doing`,
        title: `Doing...`,
        listItems: mapContentSnippets(relationships.field_moment_doing)
      }
    ],
    gainpointsPainpointsBanner: {
      path:
        relationships.field_moment_banner_image &&
        relationships.field_moment_banner_image.uri.url,
      alt: title
    },
    gainpointsPainpoints: [
      {
        icon: `painpoints`,
        title: `Pain points`,
        listItems: mapContentSnippets(relationships.field_moment_pain_points)
      },
      {
        icon: `gainpoints`,
        title: `Delights`,
        listItems: mapContentSnippets(relationships.field_moment_gain_points)
      }
    ],
    insightStarterPointsBlock: {
      blockTitle: `Insight Starter Points`,
      moreInfoLink: {
        to: `/page-url/`,
        text: `Learn more about insights`
      },
      insightStarterPoints: mapContentSnippets(
        relationships.field_insight_starter_points
      )
    },
    relatedMoments: {
      title: `Related Moments`,
      moments: relationships.field_related_moments.map((relatedMoment) => ({
        id: relatedMoment.drupal_id,
        image: {
          path:
            relatedMoment.relationships.field_moment_image &&
            relatedMoment.relationships.field_moment_image.relationships.image
              .uri.url,
          alt: relatedMoment.title
        },
        stageTitle: relatedMoment.relationships.field_moment_stage.title,
        title: relatedMoment.title,
        url: relatedMoment.path.alias,
        lifestage: relatedMoment.relationships.field_pet_lifestage[0].name,
        petParentRole:
          relatedMoment.relationships.field_pet_lifestage[0].field_parent_role,
        petParentRoleDescription:
          relatedMoment.relationships.field_pet_lifestage[0]
            .field_parent_role_description,
        petParentRoleImage: {
          path: relatedMoment.relationships.field_pet_lifestage[0].relationships
            ?.field_parent_role_image?.image.imageDerivatives.links.large.href,
          alt: relatedMoment.relationships.field_pet_lifestage[0].relationships
            ?.field_parent_role_image?.image.alt
        }
      }))
    },
    relatedArticles:
      relationships.node__article &&
      relationships.node__article.map((relatedArticle) => ({
        id: relatedArticle.drupal_id,
        title: relatedArticle.title,
        url: relatedArticle.path.alias,
        imageUrl:
          relatedArticle.relationships.field_article_image &&
          relatedArticle.relationships.field_article_image.relationships.image
            .uri.url,
        imageAlt:
          relatedArticle.relationships.field_article_image &&
          relatedArticle.relationships.field_article_image.image.alt,
        created: Object.freeze(new Date(relatedArticle.created)),
        readTime: relatedArticle.field_article_read_time
      })),
    petPawtal: {
      link: {
        title: field_pet_pawtal_video_link && field_pet_pawtal_video_link.title,
        url: field_pet_pawtal_video_link && field_pet_pawtal_video_link.url
      },
      videos:
        relationships.field_pet_pawtal_video &&
        relationships.field_pet_pawtal_video.map((petPawtalVideo) => ({
          title: petPawtalVideo.field_video_,
          url: petPawtalVideo.field_link && petPawtalVideo.field_link.url,
          imageUrl:
            petPawtalVideo.relationships.field_video_image &&
            petPawtalVideo.relationships.field_video_image.uri.url,
          imageAlt:
            petPawtalVideo.relationships.field_video_image &&
            petPawtalVideo.relationships.field_video_image.relationships
              .media__image[0].image.alt,
          created: Object.freeze(
            new Date(petPawtalVideo.field_video_date || petPawtalVideo.created)
          )
        }))
    }
  };
};
