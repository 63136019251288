import React from 'react';
import { graphql } from 'gatsby';

import munger from './moment.munger';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import MomentTemplate from '@components/templates/MomentTemplate/MomentTemplate';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

const MomentPage = ({ data, location }) => {
  const momentsJson = munger(data);
  return (
    <Layout location={location} species={momentsJson.species}>
      <SEO
        title={momentsJson.momentHeader.title}
        description={momentsJson.momentHeader.description}
      />
      <GTMPageLoad
        {...{
          stageTitle: momentsJson.momentHeader.stage,
          momentTitle: momentsJson.momentHeader.title,
          page_type: `moment`,
          page_taxonomy_field: `Moment`,
          page_species: momentsJson.species,
          page_name: momentsJson.momentHeader.title
        }}
      />

      <MomentTemplate location={location} data={momentsJson} />
    </Layout>
  );
};

export const query = graphql`
  query MomentPage($PageId: String!) {
    nodeMomentJson(drupal_id: { eq: $PageId }) {
      drupal_id
      title
      field_description {
        value
      }
      path {
        alias
      }
      field_synapse_link {
        title
        url
      }
      field_pet_pawtal_video_link {
        title
        url
      }
      relationships {
        field_moment_image {
          relationships {
            image {
              uri {
                url {
                  id
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        field_moment_stage {
          title
          path {
            alias
          }
          relationships {
            field_species {
              name
            }
          }
        }
        field_moment_thinking {
          drupal_id
          field_snippet {
            value
          }
        }
        field_moment_feeling {
          drupal_id
          field_snippet {
            value
          }
        }
        field_moment_doing {
          drupal_id
          field_snippet {
            value
          }
        }
        field_moment_banner_image {
          uri {
            url {
              id
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        field_moment_pain_points {
          drupal_id
          field_snippet {
            value
          }
        }
        field_moment_gain_points {
          drupal_id
          field_snippet {
            value
          }
        }
        field_insight_starter_points {
          drupal_id
          title
          field_snippet {
            value
          }
        }
        field_related_moments {
          drupal_id
          title
          path {
            alias
          }
          relationships {
            field_moment_image {
              relationships {
                image {
                  uri {
                    url {
                      id
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            field_moment_stage {
              title
              path {
                alias
              }
            }
            field_pet_lifestage {
              name
            }
          }
        }
        node__article {
          id
          title
          path {
            alias
          }
          field_article_read_time
          created
          relationships {
            field_article_image {
              image {
                alt
              }
              relationships {
                image {
                  uri {
                    url {
                      id
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
        field_pet_pawtal_video {
          created
          field_link {
            url
          }
          field_video_
          field_video_date
          relationships {
            field_video_image {
              uri {
                url {
                  id
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              relationships {
                media__image {
                  image {
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MomentPage;
