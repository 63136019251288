import React from 'react';
import PropTypes from 'prop-types';
import SnippetCard from '@components/organisms/SnippetCard/SnippetCard';

import './SnippetCardList.scss';

const SnippetCardList = ({ maxResultsToDisplay = 0, results = [] }) => {
  const snippetItems = (maxResultsToDisplay > 0 ? results.slice(0, maxResultsToDisplay) : results).map(mapResults);
  return (
    <ul
      className={`mars-snippet-card-list mars-cols mars-padding-bottom--lg mars-cols--2-cols`}>
      {snippetItems.length > 0 && snippetItems}
    </ul>
  );
};

const mapResults = (
  { category = ``, description, id, image, moment, momentUrl, market, source, title },
  index
) => {
  return (
    <li
      className="mars-snippet-card-list__item mars-margin-bottom--md"
      key={id || index}>
      <SnippetCard
        location={location}
        id={id}
        taxonomy={category.replace(`_`, ``)}
        rawText={description}
        image={image}
        imageTitle={title}
        source={source}
        market={market}
        moment={moment}
        momentUrl={momentUrl}></SnippetCard>
    </li>
  );
};

export default SnippetCardList;

SnippetCardList.propTypes = {
  results: PropTypes.array.isRequired,
  maxResultsToDisplay: PropTypes.number
};
