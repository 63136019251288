import React from 'react';
import { Link } from 'gatsby';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './BackStageHero.scss';

const BackStageHero = ({location}) => {

  // If this is the first page that a User lands on then don't show this button.
  if (location.key === `initial`) {
    return null;
  }

  const backToPreviousPage = () => {
    window.history.back();
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) { // Enter
      backToPreviousPage();
    }
  }

  return (
    <div className="mars-back-to-overview-hero mars-margin-bottom--xl" tabIndex="0" onKeyUp={onKeyUp}>
      <div className="mars-container">
        <a className={`mars-button mars-button--secondary`} onClick={backToPreviousPage}>
          <MarsIcon className="mars-margin-right--xxs mars-icon--back-to-overview" icon={`left`} />
          <span>Back</span>
        </a>
      </div>
    </div>
  );
};

export default BackStageHero;
