import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const SkeletonText = ({ width = null, children }) =>
  children ? children : <Skeleton width={width} />;

export default SkeletonText;

SkeletonText.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node
};
