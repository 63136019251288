import React from 'react';
import { useElastic } from '@state/providers/elastic';
import InsightStarterPointsBlock from '@components/organisms/InsightStarterPointsBlock/InsightStarterPointsBlock';
import InteractiveListsBlock from '@components/organisms/InteractiveListsBlock/InteractiveListsBlock';
import MomentHeader from '@components/organisms/MomentHeader/MomentHeader';
import PainGainBlock from '@components/molecules/PainGainBlock/PainGainBlock';
import RelatedContent from '@components/organisms/RelatedContent/RelatedContent';
import RelatedMoments from '@components/organisms/RelatedMoments/RelatedMoments';
import SnippetCardList from '@components/organisms/SnippetCardList/SnippetCardList';
import SynapseBanner from '@components/organisms/SynapseBanner/SynapseBanner';
import OpenMsFormButton from '@components/molecules/OpenMsFormButton/OpenMsFormButton';
import { useLocalStorage } from '@helpers';
import { navigate } from 'gatsby-link';

const MomentTemplate = ({ location, data }) => {
  const { results } = useElastic(`data_snippets`, `id`, data.id);
  const [searchTerm, setSearchTerm] = useLocalStorage(`searchTerm`, ``);

  const handleSearchLink = () => {
    setSearchTerm(data.momentHeader.title);
    navigate(`/search`, {
      state: {
        referrer: `moment`,
        term: data.momentHeader.title
      }
    });
  };

  return (
    <article>
      <MomentHeader id={data.id} location={location} {...data.momentHeader} />

      {/* Think Feeling Doing Block */}
      <div className="mars-container mars-margin-y--xl">
        <InteractiveListsBlock interactiveLists={data.thinkingFeelingDoing} />
      </div>

      {/* Painpoints Gainpoints Block */}
      <PainGainBlock painGainPoints={data.gainpointsPainpoints} />

      {/* Insight Starter Points Block */}
      <InsightStarterPointsBlock {...data.insightStarterPointsBlock} />

      {/* Related Articles Block and Pet Pawtal Videos Block */}
      <RelatedContent
        articles={data.relatedArticles}
        petPawtalVideos={data.petPawtal.videos}
        allVideosLink={data.petPawtal.link}
      />

      <div className="mars-container">
        <h2 className="mars-gamma mars-text--center mars-max-width--md mars-margin-bottom--sm">
          Recent data points on this moment
        </h2>
        <SnippetCardList
          preFilter={true}
          location={location}
          results={results.dataSnippets}
          maxResultsToDisplay={4}
        />
        <div className="mars-button-container mars-text--center mars-margin-bottom--xl">
          <button
            className="mars-button mars-button--primary"
            onClick={handleSearchLink}>
            <span className="mars-button__text">
              View all Data Points from this Moment
            </span>
          </button>
        </div>

        <SynapseBanner {...data.synapseBanner} />
      </div>

      <RelatedMoments layout="regular" {...data} />
      <OpenMsFormButton formUrl="https://forms.office.com/Pages/ResponsePage.aspx?id=OskoFRcZoE-1ALqcjkyZPyrIsmwGEu9EpNkZBhIzltBUNUE5MUIyOEtDUFM0UVNGQ0dJTUkwMENFUS4u&embed=true" />
    </article>
  );
};

export default MomentTemplate;
