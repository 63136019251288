import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@state/actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import { useInCollections } from '@helpers';

import './PainGainBlock.scss';

// This is a child component of PainGainBlock - It has been abstracted
// only to reduce repeated code. This component will not be exported
// as it will not be used anywhere else.
const PainGainSingleSnippet = ({painGainSingleData}) => {

  const dispatch = useDispatch();
  const focusOnModalCloseRef = useRef(null);
  const isInCollections = useInCollections(`contentSnippets`, painGainSingleData.id);
  const text = painGainSingleData.text ? painGainSingleData.text.replace(/(<([^>]+)>)/gi, ``) : null;

  return (
    <li
      key={painGainSingleData.id}
      ref={focusOnModalCloseRef}
      tabIndex="0"
      className='mars-pain-gain-list__item'
    >
      <button
        aria-label="Save"
        className="mars-pain-gain-section__save-btn mars-button--svg"
        onClick={() =>
          dispatch(
            actions.openAddToCollection(
              `contentSnippets`,
              { id: painGainSingleData.id, text: text },
              focusOnModalCloseRef
            )
          )
        }>
        <MarsIcon
          icon="save"
          className={classnames({ 'mars-icon--fill': isInCollections })}
        />
      </button>
      {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </li>
  )

}

// This is a child component of PainGainBlock - It has been abstracted
// only to reduce repeated code. This component will not be exported
// as it will not be used anywhere else.
const PainGainSection = ({painGainData}) => {
  const cleanSectionTitle = painGainData.title.replace(/\s+/g, `-`).toLowerCase();
  // We have been asked to reduce the number of visible pain / gain points so that
  // this section doesn't take up too much real estate on mobile devices. So we
  // are using the slice method here, to only get the first 6 items of the array.
  const reducedListItems = painGainData.listItems.slice(0, 6);
  return (
    // The section title is being passed in here to apply the colours to the interactive cards.
    // If these section titles change in the future we will need to update the relevant sass file
    // because the classes are being targetted specifically. Eg. .mars-pain-points.
    // Change the title delights back to positives as that is what is used as the classname.
    // ToDo: Refactor conditional statement code when the snippet types have been renamed in the CMS. 
    <div className={`mars-pain-gain-section mars-${cleanSectionTitle === `delights` ? `positives` : cleanSectionTitle}`}>
      <div className='mars-pain-gain-section__title'>
        <MarsIcon icon={painGainData.icon} />
        <p className='mars-delta mars-pain-gain-section__title-text'>{painGainData.title}</p>
      </div>
      <ul className='mars-pain-gain-list mars-cols mars-cols--2-cols'>
        {reducedListItems.map(item => {
          return <PainGainSingleSnippet key={item.id} painGainSingleData={item} />
        })}
      </ul>
    </div>
  )
}

const PainGainBlock = ({ painGainPoints }) => {
  return (
    <section className='mars-pain-gain-block mars-padding-y--lg'>
      <div className="mars-container">
        <div className="mars-pain-gain-wrapper">
          {painGainPoints.map(section => {
            return <PainGainSection key={section.title} painGainData={section} />
          })}
        </div>
      </div>
    </section>
  );
};

PainGainBlock.propTypes = {
  painGainPoints: PropTypes.array.isRequired
};

export default PainGainBlock;
