import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@state/actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import { useInCollections } from '@helpers';

import './InteractiveList.scss';

const InteractiveList = ({ icon, title, listItems }) => {
  const dispatch = useDispatch();

  const listItemsItems = listItems.map((item, index) => {
    const isInCollections = useInCollections(`contentSnippets`, item.id);
    const focusOnModalCloseRef = useRef(null);
    const text = item.text ? item.text.replace(/(<([^>]+)>)/gi, ``) : null;

    return (
      <li
        className="mars-interactive-list__list-item"
        key={item.id}
        ref={focusOnModalCloseRef}
        tabIndex="0">
        {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
        <div dangerouslySetInnerHTML={{ __html: item.text }} />
        <button
          aria-label="Save"
          className="mars-interactive-list__save-btn mars-button--svg"
          onClick={() =>
            dispatch(
              actions.openAddToCollection(
                `contentSnippets`,
                { id: item.id, text },
                focusOnModalCloseRef
              )
            )
          }>
          <MarsIcon
            icon="save"
            className={classnames({ 'mars-icon--fill': isInCollections })}
          />
        </button>
      </li>
    );
  });

  return (
    <article className="mars-interactive-list">
      <div className="mars-interactive-list__icon">
        <MarsIcon icon={icon} width="50px" height="50px" />
      </div>
      <h2 className="mars-interactive-list__title mars-delta">{title}</h2>
      <ul className="mars-interactive-list__list">{listItemsItems}</ul>
    </article>
  );
};

export default InteractiveList;

InteractiveList.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};
