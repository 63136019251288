import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from 'gatsby-link';
import ArticleCard from '@components/molecules/ArticleCard/ArticleCard';
import PetPawtalCard from '@components/molecules/PetPawtalCard/PetPawtalCard';
import './RelatedContent.scss';

const RelatedContent = ({
  articles = [],
  petPawtalVideos = [],
  allVideosLink
}) => {
  const showArticles = articles && articles.length > 0;
  const showPetPawtalVideos = petPawtalVideos && petPawtalVideos.length > 0;

  if (!showArticles && !showPetPawtalVideos) {
    return null;
  }

  return (
    <section className={`mars-related-content mars-margin-bottom--xl`}>
      <div
        className={classnames(
          `mars-container`,
          `mars-related-content--flex`,
          {
            'mars-related-content--cards mars-related-content--nowrap':
              showArticles && showPetPawtalVideos
          }
        )}>
        {showArticles && (
          <div
            className={classnames(`mars-related-content__articles`, { 'mars-related-content--flex-item': showPetPawtalVideos })}>
            <h2 className="mars-gamma mars-margin-bottom--sm mars-text--center mars-related-content__title">
              Recent articles on this moment
            </h2>
            <div
              className={classnames(`mars-related-content--flex`, { 'mars-related-content--cards': !showPetPawtalVideos })}>
              {articles.slice(0, 2).map((article) => (
                <ArticleCard data={article} key={article.title} />
              ))}
            </div>
            <div className="mars-button-container mars-margin-bottom--xl mars-text--center mars-related-content__more-button">
              <Link to="/articles">
                <button className="mars-button mars-button--primary">
                  <span className="mars-button__text">View more articles</span>
                </button>
              </Link>
            </div>
          </div>
        )}
        {showPetPawtalVideos && (
          <div
            className={classnames(`mars-related-content__videos`, { 'mars-related-content--flex-item': showArticles })}>
            <h2 className="mars-gamma mars-margin-bottom--sm mars-text--center mars-related-content__title">
              Hear from pet parents directly
            </h2>
            <p className="mars-text--center mars-related-content__title">
              Powered by Pet Pawtal
            </p>
            <div
              className={classnames(`mars-related-content--flex`, { 'mars-related-content--cards': !showArticles })}>
              {petPawtalVideos.slice(0, 2).map((video) => (
                <PetPawtalCard data={video} key={video.title} />
              ))}
            </div>
            {allVideosLink?.url && (
              <div className="mars-button-container mars-margin-bottom--xl mars-text--center mars-related-content__more-button">
                <a href={allVideosLink.url} target="_blank">
                  <button className="mars-button mars-button--primary">
                    <span className="mars-button__text">
                      {allVideosLink.title}
                    </span>
                  </button>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

RelatedContent.propTypes = {
  articles: PropTypes.array,
  petPawtalVideos: PropTypes.array
};

export default RelatedContent;
