import React, { useState, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import ShareContentModal from '@components/molecules/ShareContentModal/ShareContentModal';
import classnames from 'classnames';
import BackStageHero from '@components/molecules/BackStageHero/BackStageHero';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import Leaf from '@svgs/moment-header-leaf.svg';
import Road from '@svgs/moment-header-road.svg';

import { useDispatch } from 'react-redux';
import { useInCollections } from '@helpers';
import actions from '@state/actions';

import './MomentHeader.scss';
import { getDrupalURL } from '@helpers';

const MomentHeader = ({ id, stage, title, description, image, location }) => {
  const dispatch = useDispatch();
  const isInCollections = useInCollections(`moments`, id);
  const addBtnRef = useRef(null);
  const shareBtnRef = useRef(null);

  const openAddToCollection = () => {
    dispatch(
      actions.openAddToCollection(
        `moments`,
        { id, momentTitle: title, stageTitle: stage },
        addBtnRef
      )
    );
  };

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => {
    setIsShareModalOpen(false);
    shareBtnRef.current.focus();
  };

  return (
    <header className="mars-moment-header mars-padding-bottom--xl">
      <div className="mars-container mars-padding-y--lg">
        <BackStageHero location={location} />
      </div>
      <div className="mars-moment-header__leaf-container">
        <Leaf />
      </div>
      <div className="mars-moment-header__road-container">
        <Road />
      </div>
      <div className="mars-container">
        <div className="mars-moment-header__inner">
          <div className="mars-moment-header__content mars-margin-y--md">
            <div className="mars-moment-header__stage mars-zeta mars-text-colour--text-meta-hero">
              {stage} / Moment
            </div>
            <h1 className="mars-moment-header__title mars-beta">{title}</h1>
            {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
            <div
              className="mars-moment-header__description mars-body-large"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div>
              <button
                className="mars-button mars-button--icon-button mars-margin-right--sm"
                onClick={openAddToCollection}
                ref={addBtnRef}>
                <div className="mars-icon-button__icon-wrapper mars-margin-right--xxs">
                  <MarsIcon
                    icon="save"
                    className={classnames(`mars-card__save-icon`, {
                      'mars-icon--fill': isInCollections
                    })}
                    width="22px"
                    height="22px"
                  />
                </div>
                <span className="mars-icon-button__text">
                  Add to collection
                </span>
              </button>
              <button
                className="mars-button mars-button--icon-button"
                onClick={openShareModal}
                ref={shareBtnRef}>
                <div className="mars-icon-button__icon-wrapper mars-margin-right--xxs">
                  <MarsIcon
                    className="mars-icon-button__icon"
                    icon="share"
                    width="22px"
                    height="22px"
                  />
                </div>
                <span className="mars-icon-button__text">Share</span>
              </button>
            </div>
          </div>
          {image && (
            <div className="mars-moment-header__image">
              <MarsIcon
                icon="dots"
                width="220px"
                height="175px"
                className="mars-moment-header__shape mars-icon--fill mars-icon--brand2"
              />
              {image && (
                <>
                  {/* <img src={`${getDrupalURL()}${image.path}`} alt={image.alt} /> */}
                  <GatsbyImage image={getImage(image.path)} alt={image.alt} />
                </>
              )}
            </div>
          )}
        </div>
        <ShareContentModal
          shareURL={location.href}
          isOpen={isShareModalOpen}
          onDismiss={closeShareModal}
        />
      </div>
    </header>
  );
};

export default MomentHeader;

MomentHeader.propTypes = {
  id: PropTypes.string,
  stage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    path: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  location: PropTypes.object.isRequired
};
