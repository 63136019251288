import React, { useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useDispatch, useSelector } from 'react-redux';
import { useInCollections } from '@helpers';
import actions from '@state/actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'gatsby-link';
import Card from '@components/atoms/Card/Card';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import SkeletonText from '@components/atoms/SkeletonText/SkeletonText';

import './MomentCard.scss';
import { getDrupalURL } from '@helpers';

const MomentCard = ({
  id,
  url,
  image,
  stageTitle,
  title,
  species,
  lifestage
}) => {
  const dispatch = useDispatch();
  const isInCollections = useInCollections(`moments`, id);
  const collection = useSelector((state) => state.collections.collection);
  const focusOnModalCloseRef = useRef(null);

  const openAddToCollection = () => {
    dispatch(
      actions.openAddToCollection(
        `moments`,
        { id, momentTitle: title, stageTitle },
        focusOnModalCloseRef
      )
    );
  };

  const onActionBtnClick = (e) => {
    if (typeof collection !== `undefined`) {
      dispatch(
        actions.openRemoveFromCollection(`moments`, id, focusOnModalCloseRef)
      );
    } else {
      openAddToCollection();
    }
  };

  const CardContent = () => (
    <>
      <div className="mars-moment-card__stage mars-padding--xs">
        <span className="mars-text-colour--brand4 mars-moment-card__stage-inner">
          <SkeletonText
            width={100}>{`${lifestage} Parent / ${stageTitle}`}</SkeletonText>
        </span>
      </div>
      <div className="mars-moment-card__image-wrapper">
        {image.path && typeof image.path === 'string' ? (
          <>
            <img
              className="mars-moment-card__image"
              src={`${image.path}`}
              alt={image.alt}
            />
          </>
        ) : (
          <>
            <GatsbyImage
              className="mars-moment-card__image"
              image={getImage(image.path)}
              alt={image.alt}
            />
          </>
        )}
      </div>
      <div className="mars-moment-card__content mars-padding--xs">
        <h3 className="mars-moment-card__title mars-delta mars-text-colour--brand4">
          <SkeletonText width={150}>{title}</SkeletonText>
        </h3>
      </div>
    </>
  );

  return (
    <>
      <Card className="mars-card--hover-reveal">
        <article className="mars-moment-card">
          {url ? (
            <Link
              className={`mars-moment-card__link`}
              to={url}
              ref={focusOnModalCloseRef}>
              <CardContent />
            </Link>
          ) : (
            <CardContent />
          )}
          <button
            className="mars-moment-card__save-btn mars-card__save-btn mars-button--svg"
            onClick={onActionBtnClick}
            aria-label="Save"
            tabIndex="0">
            <MarsIcon icon="trash" className="mars-card__trash-icon" />
            <MarsIcon
              icon="save"
              className={classnames(`mars-card__save-icon`, {
                'mars-icon--fill': isInCollections
              })}
            />
          </button>
        </article>
      </Card>
    </>
  );
};

MomentCard.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string,
  image: PropTypes.shape({
    path: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  stageTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default MomentCard;
