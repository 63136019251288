import React from 'react';
import PropTypes from 'prop-types';
import Carousel from '@components/organisms/Carousel/Carousel';
import InsightStarterPoint from '../InsightStarterPoint/InsightStarterPoint';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './InsightStarterPointsBlock.scss';

const InsightStarterPointsBlock = ({ blockTitle, insightStarterPoints }) => {
  // Create a list of Insight Starter Points from insightStarterPoints array.
  const insightStarterPointItems = insightStarterPoints.map(
    (insightStarterPoint, index) => {
      return (
        <div
          className="mars-insight-starter-point-block__item"
          key={insightStarterPoint.text + index}>
          <InsightStarterPoint
            id={insightStarterPoint.id}
            text={insightStarterPoint.text}
          />
        </div>
      );
    }
  );

  return (
    <section className="mars-insight-starter-point-block mars-padding-y--lg">
      <div className="mars-container mars-margin-y--xl">
        <div className="mars-margin-y--xl">
          <div className="mars-grid mars-insight-starter-point-block__mobile-block">
            <div className="mars-grid-col-4">
              <MarsIcon icon="insight" width="36px" height="36px" className="mars-icon mars-margin-top--sm" />
              <h2 className="mars-insight-starter-point-block__title mars-beta mars-text-colour--brand1">
                {blockTitle}
              </h2>
            </div>
            <div className="mars-grid-col-8">
              <Carousel>{insightStarterPointItems}</Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InsightStarterPointsBlock;

InsightStarterPointsBlock.propTypes = {
  blockTitle: PropTypes.string,
  insightStarterPoints: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  )
};
