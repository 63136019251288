import React from 'react';
import PropTypes from 'prop-types';
import InteractiveList from '@components/organisms/InteractiveList/InteractiveList';

import './InteractiveListsBlock.scss';

const InteractiveListsBlock = ({ interactiveLists }) => {
  const interactiveListItems = interactiveLists.map(
    (interactiveList, index) => {
      return (
        <div className="mars-grid-col-4 mars-interactive-lists" key={interactiveList.title + index}>
          <InteractiveList
            icon={interactiveList.icon}
            title={interactiveList.title}
            listItems={interactiveList.listItems}
          />
        </div>
      );
    }
  );

  return (
    <>
      <div className="mars-interactive-lists-block mars-grid">
        {interactiveListItems}
      </div>
    </>
  );
};

export default InteractiveListsBlock;

InteractiveListsBlock.propTypes = {
  interactiveLists: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string.isRequired,
      listItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ),
  className: PropTypes.string
};
