import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './SynapseBanner.scss';

const SynapseBanner = ({ title, url  }) => {
  return (
    <div className="mars-container mars-margin-y--xl">
      <article className="mars-synapse-block mars-padding-y--lg mars-text--center">
        <div className="mars-synapse-block__image-container">
          <img
            className={`mars-synapse-block__image`}
            src="/synapse/synapse-logo.png"
            alt={`Synapse`}
          />
        </div>
        <h1 className="mars-synapse-block__title mars-gamma">View the latest documents from Synapse</h1>
        <a
          href={url ? url : `https://synapse.mip.marketlogicsoftware.com/published/5f4cedfda59e1900015fb08b/5f4923dda59e1900015faf81/`}
          target="_blank"
          className="mars-button mars-margin-right--xs">
            {title ? title : `View latest documents`}
        </a>
      </article>
    </div>
  );
};

export default SynapseBanner;

SynapseBanner.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};
