import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

/**
 *  Include Slick library styles taken from
 *  https://github.com/kenwheeler/slick/tree/master/slick
 **/
import './slick.scss';

// Custom Mars carousel styles/overrides.
import './Carousel.scss';

const Carousel = ({ children, settings }) => {

  // Prevent React console errors by preventing currentSlide/slideCount props from being added to custom nav elements.
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );

  const defaultSettings = {
    arrows: true,
    nextArrow: (
      <SlickButtonFix>
        <MarsIcon icon="button_right" width="36px" height="36px" />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <MarsIcon icon="button_left" width="36px" height="36px" />
      </SlickButtonFix>
    ),
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnHover: true,
    accessibility: true,
    adaptiveHeight: true,
    focusOnChange: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const sliderSettings = Object.assign({}, defaultSettings, settings);

  if (children.length > 1){
    return (
      <>
        <Slider className="mars-carousel" {...sliderSettings}>{children}</Slider>
      </>
    );
  } else {
    return (
      <>
        {children}
      </>
    );
  }
  
};



export default Carousel;

Carousel.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object
};
